<template>
  <base-material-card icon="mdi-clipboard-text" :title="$t('menu.area.list')" class="px-5 py-3">
    <div class="d-flex" style="margin-bottom: 60px">
      <div style="flex: 0 0 calc(50% - 32px); max-width: calc(50% - 32px)"></div>
      <base-dropdown-multiple-filter
        :items="provinceFilters"
        :itemTitle="'name'"
        :itemValue="'id'"
        @changeFilter="changeProvinceFilter"
        style="flex: 0 0 25%; max-width: 25%"
        :multiple="false"
        label="Provinsi"
      ></base-dropdown-multiple-filter>
      <base-dropdown-multiple-filter
        :items="cityFilters"
        :itemTitle="'name'"
        :itemValue="'id'"
        @changeFilter="changeCityFilter"
        style="flex: 0 0 25%; max-width: 25%"
        class="ml-4"
        :multiple="false"
        label="Kota"
      ></base-dropdown-multiple-filter>
    </div>
    <base-add-button :permissions="permissions" :basePath="basePath" :title="$t('area.add')">
    </base-add-button>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="text-center primary--text">
            {{ $t('general.actions') }}
          </th>
          <th class="primary--text">ID</th>
          <th class="primary--text">
            {{ $t('area.name') }}
          </th>
          <th class="primary--text">
            {{ $t('area.city') }}
          </th>
          <th class="primary--text">
            {{ $t('area.province') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>
            <div class="d-flex justify-center align-center">
              <base-edit-button
                :permissions="permissions"
                :basePath="basePath"
                :id="item.id"
              ></base-edit-button>
              <base-delete-button
                :permissions="permissions"
                :id="item.id"
                :showIcon="true"
                @deleteAction="deleteAction"
              ></base-delete-button>
            </div>
          </td>
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.city }}</td>
          <td>{{ item.province }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const Pagination = () => import('@/components/utils/fractal-pagination');
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.area.basePath,
      websiteUrl: (state) => state.global.websiteUrl,
      cityFilters: (state) => state.area.cityFilters,
      provinceFilters: (state) => state.area.provinceFilters,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    async deleteAction(id) {
      console.log('DELETE AREA UUID: ', id);
      try {
        await this.$store.dispatch('area/delete', id);
        this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('area.delete.successMsg'),
          'success',
        );
        await this.$store.dispatch('area/getInitData', this.$route.query);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    changeCityFilter(val) {
      this.$emit('changeCityFilter', val);
    },
    changeProvinceFilter(val) {
      this.$emit('changeProvinceFilter', val);
    },
  },
};
</script>
